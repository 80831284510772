import * as React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Seo from "../components/seo"

const MembraneSciencePage = () => {

    return (
        <Layout>
        <Seo title='Introduction to membrane science from the Open Membrane Database' />
            <section className="section">
                <div className="container is-max-desktop">
                    <h1 className="title is-spaced">Introduction to membrane science</h1>
                    <h2 className="subtitle is-4 mt-6">What is membrane technology?</h2>
                    <p className="mb-3">
                        Membrane technology allows the separation of different species from a liquid or gas stream.
                    </p>
                    <p className="mb-3">
                        <b>Membranes are semi-permeable materials</b>: they selectively allow certain species to pass, while retaining others. For separation to occur, a driving force must be applied to membranes to cause one species to permeate the membrane. This driving force is often a pressure, heat, or concentration gradient, depending on the membrane process that is implemented. Separation processes utilizing membrane technology are often preferred due to their <b>energy efficiency, modularity, and small process footprint</b>, compared to conventional separation processes such as distillation and extraction.
                    </p>
                    <div
                        className="has-text-centered my-5">
                        <StaticImage
                            src="../images/membrane_filtration_with_text.png"
                            alt="Open Membrane Database Reverse Osmosis membrane filtration illustration"
                            width={500}
                        />
                    </div>
                    <h2 className="subtitle is-4 mt-6">Membranes for pressure-driven applications</h2>
                    <p className="mb-3">
                        The structure and performance of membranes can vary significantly for different processes. In liquid separations, membranes are primarily categorized according to the size of the components they can separate from the feed stream. For pressure-driven separation processes of liquid streams, the following membrane categories exist:
                    </p>
                    <div className="content mb-3">
                        <ul>
                            <li>
                                <b>microfiltration</b> (MF) membranes have the largest pore sizes and can remove large species with a diameter &gt; 1 mm such as bacteria, protozoa, and colloidal substances.
                            </li>
                            <li>
                                <b>ultrafiltration</b> (UF) membranes have smaller pores which can reject species &gt; 100 nm such as proteins and natural organic matter and are often used for processes such as dialysis.
                            </li>
                            <li>
                                <b>nanofiltration</b> (NF) membranes remove smaller dissolved species with a molecular weight from 150 – 1000 g mol<sup>-1</sup>, such as viruses, multivalent ions, and small organic molecules.
                            </li>
                            <li>
                                <b>reverse osmosis</b> (RO) rejects the smallest ions and organic materials with a molecular weight around 100 g mol<sup>-1</sup> and is therefore used to desalinate water.
                            </li>
                        </ul>
                    </div>
                    <h2 className="subtitle is-4 mt-6">
                        Working principle of pressure-driven membranes
                    </h2>
                    The transport of the liquid feed stream across the membrane and the rejection of solutes varies for the different classes of membranes. MF and UF have distinct pores which allow convective flow of liquids while preventing large solutes from crossing the membrane. In contrast, NF and RO have dense polymer separation layers which require species to absorb into the polymer layer and diffuse across them. As a result, separation in NF and RO is dominated by more complicated interactions dependent on the size, charge, and affinity of solutes and solvent for the dense polymer layer.
                    <h2 className="subtitle is-4 mt-6">
                        Reverse osmosis
                    </h2>
                    <p className="mb-3">
                        Reverse osmosis (RO) derives its name from the reversal of natural osmosis by the application of pressure. In RO processes, a pressure gradient is applied to desalinate saline water streams by allowing the selective permeation of water and the rejection of salts by a semipermeable membrane.
                    </p>
                    <div
                        className="has-text-centered my-5">
                        <StaticImage
                            src="../images/Osmosis_Reverse_Osmosis.png"
                            alt="Open Membrane Database : Osmosis versus Reverse Osmosis illustration"
                            width={500}
                        />
                    </div>
                    <p className="mb-3">
                        RO membrane structure is typically asymmetric or a <b>thin film composite</b> (TFC), where a thin, dense separation layer sits on top of a more porous support layer. State-of-the-art RO membranes are TFCs with dense polyamide separation layers (50 – 200 nm thick, ~5 Å free volume element size) with a polysulfone support layer (100 mm thick, 10 nm pore size). The rejection of solutes is primarily dominated by size exclusion and dielectric exclusion. These mechanisms produce a <b>permeability-selectivity</b> trade-off, where water permeability decreases when salt rejection increases, and vice versa. Maximizing permeability and selectivity are desired, thus many membrane scientists seek to push the limits of this tradeoff.
                    </p>
                    <h2 className="subtitle is-4 mt-6">
                        OMD of reverse osmosis membranes
                    </h2>
                    <p className="mb-3">
                        The Open Membrane Database seeks to provide a <b>publicly available resource for membrane researchers to understand state-of-the-art membrane performance</b> as they seek to push the boundaries of the permeability-selectivity tradeoff. Currently, the OMD only focuses on <b>RO membranes</b> but it aims to establish databases for other membrane-based separations technologies such as nanofiltration (NF) and solvent-resistant nanofiltration (SRNF). Please <Link to="/about-the-open-membrane-database#contact-us">get in contact with us</Link> if you would like to contribute.
                    </p>
                </div>
            </section>
        </Layout >
    )
}

export default MembraneSciencePage
